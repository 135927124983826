import React from "react";
import { css } from "emotion";

import { Lesson, iter } from "@turbo-schedule/common";

import { useTranslation } from "../../i18n/useTranslation";
import { useStudentScheduleMachine } from "./student-schedule-machine";
import { DAYS_PER_WEEK, getLessonTimes } from "../../utils/getLessonTimes";
import { MEDIA_QUERY, useWindow } from "../../hooks/useWindow";

export type WholeWeekScheduleProps = {
	participant: string;
};

export const WholeWeekSchedule = ({ participant }: WholeWeekScheduleProps) => {
	const t = useTranslation();
	const SSM = useStudentScheduleMachine();
	const { isDesktop } = useWindow();

	const scheduleByDays = SSM.state.context.participant.scheduleByDays;
	const longestDay = scheduleByDays.reduce(
		(acc, curr) => (curr.length > acc.length ? curr : acc),
		[],
	);

	return (
		<table className={styles.table}>
			<thead className={styles.thead}>
				{!isDesktop
					? null
					: (
						<tr>
							<th className={styles.th}>
								{participant.split(" ").map((x) => (
									<div>{x}</div>
								))}
							</th>

							{iter(DAYS_PER_WEEK, (i) => (
								<th className={styles.th} key={i}>
									{t("weekday")(i)}
								</th>
							))}
						</tr>
					)
				}
			</thead>
			<tbody>
				{longestDay.map((_, lesson) => {
					const time = getLessonTimes(lesson);
					return (
						<tr key={lesson}>
							<th className={styles.th}>
								{time.startTime}
								<br />
								{time.endTime}
							</th>
							{scheduleByDays.map((day, i) => (
								<td key={i} className={styles.td}>
									{getLessonName(day[lesson], isDesktop)}
								</td>
							))}
						</tr>
					);
				})}
			</tbody>
		</table>
	);
};

function getLessonName(lesson: Lesson | null, isDesktop: boolean, { maxWordLenOnMobile = 3 } = {}): string {
	if (!lesson?.name) return "";

	if (isDesktop) {
		return lesson.name;
	}

	return lesson.name.split(" ").map(x => x.substring(0, maxWordLenOnMobile)).join(" ");
}

const styles = {
	table: css`
		flex: 5;
		margin: 2em;

		table-layout: fixed;
		overflow: hidden;

		${MEDIA_QUERY.notDesktop} {
			margin: 0 0 0.5rem 0;
			width: 100vw;
			border-collapse: collapse;

			& tr th {
				border-left: none;
			}
			& tr:first-child > * {
				border-top: none;
			}
			& tr td:last-child {
				border-right: none;
			}
		}

	`,
	thead: css`
		border-bottom: 1px solid #000;
	`,
	th: css`
		border: 1px solid #000;
		padding: 0.25em 0.5em;
		background: hsl(0, 0%, 90%);
		vertical-align: middle;
	`,
	td: css`
		border: 1px solid #000;
		padding: 0.1em 0.25em;
		text-align: center;
		vertical-align: middle;

		${MEDIA_QUERY.notDesktop} {
			min-width: 4rem;
			min-height: 4rem;
		}
	`,
};
