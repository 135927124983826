import React, { FC } from "react";
import { css, cx } from "emotion";
import { scheduleDaysWithWeek, ScheduleDay } from "../../utils/selectSchedule";

import { dayIndexPrettyRoman } from "./day-index";
import { useTranslation } from "../../i18n/useTranslation";
import { BadgeBeta } from "../../common/Badge";
import { StickyInfoOrWarningAboutFreshOrOutdatedData } from "../navbar/StickyInfoOrWarningAboutFreshOrOutdatedData";

export interface DaySelectorMobileProps {
	selectedDay: ScheduleDay | undefined;
	handleClick: (e: React.MouseEvent, day: ScheduleDay) => any;
}

interface DaySelectorDesktopProps {
	selectedDay: ScheduleDay;
	onSelectDay: (day: ScheduleDay) => void
}

export const DaySelectorMobile: FC<DaySelectorMobileProps> = ({ selectedDay, handleClick }) => {
	return (
		<div
			className={css`
				width: 100%;
				position: sticky;
				top: 0;
				background: #fff;
				z-index: 1;

				display: flex;

				& > * {
					border-left: none !important;
				}

				& > *:last-child {
					border-right: none !important;
				}
			`}
		>
			{scheduleDaysWithWeek.map((dayIndex) => (
				<button
					type="button"
					key={dayIndex}
					onClick={(e) => handleClick(e, dayIndex)}
					className={cx(
						css`
							display: inline-block;
							min-height: 30px;

							flex: 1;
							position: relative;
						`,
						"btn",
						{ "selected": selectedDay === dayIndex },
					)}
				>
					<span>{dayIndexPrettyRoman(dayIndex)}</span>
					{dayIndex === "*" &&
						<BadgeBeta className={css`
							font-size: 1rem;
							top: -10px;
							right: -12px;
						`}
						/>
					}
				</button>
			))}
		</div>
	)
};

export const DaySelectorDesktop: FC<DaySelectorDesktopProps> = ({ selectedDay, onSelectDay }) => {
	const t = useTranslation();

	/**
	 * avoid double border with LessonsList:
	 * - have global border when it is displayed (so that LessonsList doesn't need to)
	 * - have local (individual) borders when LessonsList is not displayed
	 */
	const globalBorderRight: boolean = selectedDay !== "*";

	return (<nav
		className={cx(
			css`
				/* background: lightcyan; */
				display: flex;
				flex-direction: column;
				flex: 0;
				flex-basis: 2;

				min-width: 20em; /** TODO - does not prevent the shrinking when we want it to prevent:/ */

				height: 100%;
				max-height: 100%;
				overflow-x: hidden;
				overflow-y: auto;
			`,
			{
				[css`border-right: 1px solid #000;`]: globalBorderRight,
			}
		)}
	>
		<ul
			className={cx(
				css`
					display: flex;
					flex-direction: column;

					height: fit-content;

					align-items: center;
					justify-content: center;

					& > * {
						flex: 1;
					}

					// & > * + * {
					& > * {
						border-top: 1px solid #000;
					}

					// border-right: 1px solid #000;

					border-bottom: 1px solid #000;
					box-sizing: border-box;
				`,
				{
					[css`border-right: 1px solid #000;`]: !globalBorderRight,
				}
			)}
		>
			{scheduleDaysWithWeek.map((dayIndex) => (
				<li
					key={dayIndex}
					className={css`
									flex-grow: 1;
									width: 100%;


									/* ${dayIndex === selectedDay && "border-left: 0.75em solid #000;"} */
									position: relative;
								`}
				>
					{dayIndex === selectedDay && (
						<span
							className={css`
								position: absolute;
								left: 0;
								top: 0;

								width: 0.75em;
								height: 100%;

								background: #000;
							`}
						/>
					)}

					<button
						type="button"
						onClick={(_e) => onSelectDay(dayIndex)}
						className={css`
										display: flex;

										align-items: center;
										// margin-left: 2rem;
										justify-content: center;

										width: 100%;
										height: 5rem;
										font-size: 2em;

										/* ${dayIndex === selectedDay && "font-weight: 700; background: #000; color: lightcyan;"} */
									`}
					>
						<span>{t("weekday")(dayIndex)}</span>
						{dayIndex === "*" &&
							<BadgeBeta  className={css`
								font-size: 1rem;
								top: 6px;
								right: 2px;
								font-weight: 550;
							`}
							/>}
					</button>
				</li>
			))}
		</ul>

		<div className={css`
			margin-top: auto;
			margin-left: 1em;

			display: flex;
			flex-direction: column;
			align-items: bottom;
			justify-content: left;

			min-height: fit-content;
			height: max-content;
			`}
		>
			<div className={css`height: 1em; width:100%`}/>
				{/* links in future */}
			<div className={css`height: 0.5em; width:100%`}/>
		</div>

		<StickyInfoOrWarningAboutFreshOrOutdatedData
			className={cx(
				css`
					border: 1px solid #000;
					border-width: 1px 0px 0 0;
					text-align: left;
				`,
				{
					[css`border-right-width: 1px;`]: !globalBorderRight,
				}
			)}
		/>
	</nav>)
};
